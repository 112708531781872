import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { getTransitionsNeedingProviderAttention } from '../transactions/transaction';
import { authInfo } from './auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { util as sdkUtil, createImageVariantConfig } from '../util/sdkLoader';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const ADD_TO_CART_REQUEST = 'app/user/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'app/user/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'app/user/ADD_TO_CART_ERROR';

export const REMOVE_FROM_CART_REQUEST = 'app/user/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'app/user/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_ERROR = 'app/user/REMOVE_FROM_CART_ERROR';

export const LISTINGS_IN_CART_REQUEST = 'app/user/LISTINGS_IN_CART_REQUEST';
export const LISTINGS_IN_CART_SUCCESS = 'app/user/LISTINGS_IN_CART_SUCCESS';
export const LISTINGS_IN_CART_ERROR = 'app/user/LISTINGS_IN_CART_ERROR';

export const RESET_CART_ERRORS = 'app/user/RESET_CART_ERRORS';

export const UPDATE_USER_CART = 'app/user/UPDATE_USER_CART';
export const REMOVE_ITEMS_FROM_CART_REQUEST = 'app/user/REMOVE_ITEMS_FROM_CART_REQUEST';
export const REMOVE_ITEMS_FROM_CART_SUCCESS = 'app/user/REMOVE_ITEMS_FROM_CART_SUCCESS';
export const REMOVE_ITEMS_FROM_CART_ERROR = 'app/user/REMOVE_ITEMS_FROM_CART_ERROR';

export const OUT_OF_STOCK = 'app/user/OUT_OF_STOCK';
// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  addToCartInProgress: false,
  addToCartError: null,
  removeFromCartInProgress: false,
  removeFromCartError: null,
  listingsInCart: [],
  listingsInCartError: null,
  listingsInCartInProgress: false,
  userCart: [],
  removeItemsFromCartInProgress: false,
  removeItemsFromCartError: null,
  outOfStockError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      const currentUser = mergeCurrentUser(state.currentUser, payload);
      const { attributes } = currentUser || {};
      const { publicData } = attributes?.profile || {};
      const { cart = [] } = publicData || {};

      const userCartWithQuantity = cart.length
        ? cart?.map(item => ({ id: item, quantity: 1 }))
        : [];
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        userCart: userCartWithQuantity,
      };
    case UPDATE_USER_CART:
      const userCart = state.userCart;
      const index = userCart.findIndex(x => x.id === payload.id);
      if (index >= 0) {
        userCart[index].quantity = payload.quantity;
      }
      return {
        ...state,
        userCart,
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      // console.error(payload);
      return { ...state, currentUserShowError: payload };
    case OUT_OF_STOCK:
      return { ...state, outOfStockError: payload };
    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };
    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        addToCartInProgress: true,
        addToCartError: null,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartInProgress: false,
        addToCartError: null,
      };
    case ADD_TO_CART_ERROR:
      return {
        ...state,
        addToCartInProgress: false,
        addToCartError: payload,
      };
    case RESET_CART_ERRORS:
      return {
        ...state,
        removeFromCartError: null,
        addToCartError: null,
      };
    case REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        removeFromCartInProgress: true,
        removeFromCartError: null,
      };
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        removeFromCartInProgress: false,
        removeFromCartError: null,
      };
    case REMOVE_FROM_CART_ERROR:
      return {
        ...state,
        removeFromCartInProgress: false,
        removeFromCartError: payload,
      };
    case LISTINGS_IN_CART_REQUEST:
      return {
        ...state,
        listingsInCartInProgress: true,
        listingsInCartError: null,
      };
    case LISTINGS_IN_CART_SUCCESS:
      return {
        ...state,
        listingsInCartInProgress: false,
        listingsInCartError: null,
        listingsInCart: payload,
      };
    case LISTINGS_IN_CART_ERROR:
      return {
        ...state,
        listingsInCartInProgress: false,
        listingsInCartError: payload,
      };
    case REMOVE_ITEMS_FROM_CART_REQUEST:
      return {
        ...state,
        removeItemsFromCartInProgress: true,
        removeItemsFromCartError: null,
      };
    case REMOVE_ITEMS_FROM_CART_SUCCESS:
      return {
        ...state,
        removeItemsFromCartInProgress: false,
        removeItemsFromCartError: null,
      };
    case REMOVE_ITEMS_FROM_CART_ERROR:
      return {
        ...state,
        removeItemsFromCartInProgress: false,
        removeItemsFromCartError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //
export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    perPage: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    perPage: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  const transitionsNeedingAttention = getTransitionsNeedingProviderAttention();
  if (transitionsNeedingAttention.length === 0) {
    // Don't update state, if there's no need to draw user's attention after last transitions.
    return;
  }

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsNeedingAttention,
    page: 1,
    perPage: NOTIFICATION_PAGE_SIZE,
  };

  dispatch(fetchCurrentUserNotificationsRequest());
  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(fetchListingsInCart());
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      console.log(storableError(e));
      dispatch(currentUserShowError(storableError(e)));
    });
};
export const fetchListingsInCart = () => (dispatch, getState, sdk) => {
  dispatch({ type: LISTINGS_IN_CART_REQUEST });
  const currentUser = getState().user.currentUser;
  if (!currentUser) {
    return dispatch({ type: LISTINGS_IN_CART_ERROR, payload: 'No current user' });
  }

  const { attributes } = currentUser || {};
  const { publicData } = attributes?.profile || {};
  const { cart = [] } = publicData || {};
  if (cart.length == 0) {
    return dispatch({ type: LISTINGS_IN_CART_SUCCESS, payload: [] });
  }
  const variantPrefix = 'listing-card';
  return sdk.listings
    .query({
      include: ['author', 'images', 'currentStock'],
      ids: cart,
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'currentStock'],
      'fields.user': [
        'profile.displayName',
        'profile.abbreviatedName',
        'profile.metadata.username',
      ],
      'fields.image': [
        // Scaled variants for large images
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Cropped variants for listing thumbnail images
        'variants.listing-card',
        'variants.listing-card-2x',
        'variants.listing-card-4x',
        'variants.listing-card-6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, 1),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, 1),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, 1),
      ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, 1),

      'limit.images': 1,
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      dispatch({ type: LISTINGS_IN_CART_SUCCESS, payload: entities });
      return entities;
    })
    .catch(e => {
      console.log('error', storableError(e));
      dispatch({ type: LISTINGS_IN_CART_ERROR, payload: storableError(e) });
    });
};
export const addToCart = (listingId, authorId) => (dispatch, getState, sdk) => {
  dispatch({ type: ADD_TO_CART_REQUEST });
  const currentUser = getState().user.currentUser;
  if (!currentUser) {
    return dispatch({ type: ADD_TO_CART_ERROR, payload: 'No current user' });
  }
  const { attributes } = currentUser || {};
  const { publicData } = attributes?.profile || {};
  const { cart = [], cartAuthorId } = publicData || {};
  if (cart?.includes(listingId)) {
    return dispatch({ type: ADD_TO_CART_ERROR, payload: 'Already in cart' });
  }
  if (cartAuthorId) {
    if (cartAuthorId !== authorId) {
      return dispatch({
        type: ADD_TO_CART_ERROR,
        payload:
          'Cannot add items from different seller. To buy from this seller, please create a new cart',
      });
    }
  }
  return sdk.currentUser
    .updateProfile({ publicData: { cart: [...cart, listingId], cartAuthorId: authorId } })
    .then(() => {
      dispatch({ type: ADD_TO_CART_SUCCESS });
      // dispatch(fetchListingsInCart());
      dispatch(fetchCurrentUser());
    })
    .catch(e => {
      dispatch({ type: ADD_TO_CART_ERROR, payload: storableError(e) });
    });
};
export const removeFromCart = listingId => (dispatch, getState, sdk) => {
  dispatch({ type: REMOVE_FROM_CART_REQUEST });
  const currentUser = getState().user.currentUser;

  if (!currentUser) {
    return dispatch({ type: REMOVE_FROM_CART_ERROR, payload: 'No current user' });
  }
  const { attributes } = currentUser || {};
  const { publicData } = attributes?.profile || {};
  const { cart = [], cartAuthorId } = publicData || {};
  if (!cart?.includes(listingId)) {
    return dispatch({ type: REMOVE_FROM_CART_ERROR, payload: 'Not in cart' });
  }
  const newCart = cart.filter(id => id !== listingId);
  return sdk.currentUser
    .updateProfile({
      publicData: { cart: newCart, cartAuthorId: cart?.length == 1 ? null : cartAuthorId },
    })
    .then(() => {
      dispatch({ type: REMOVE_FROM_CART_SUCCESS });
      dispatch({ type: OUT_OF_STOCK, payload: null });
      dispatch(fetchCurrentUser());
      // dispatch(fetchListingsInCart());
    })
    .catch(e => {
      dispatch({ type: REMOVE_FROM_CART_ERROR, payload: storableError(e) });
    });
};
export const removeAllItemsFromCart = () => (dispatch, getState, sdk) => {
  dispatch({ type: REMOVE_ITEMS_FROM_CART_REQUEST });

  return sdk.currentUser
    .updateProfile({
      publicData: { cart: [], cartAuthorId: null },
    })
    .then(() => {
      dispatch({ type: REMOVE_ITEMS_FROM_CART_SUCCESS });
      dispatch({ type: OUT_OF_STOCK, payload: null });
      dispatch(fetchCurrentUser());
      // dispatch(fetchListingsInCart());
    })
    .catch(e => {
      dispatch({ type: REMOVE_ITEMS_FROM_CART_ERROR, payload: storableError(e) });
    });
};
export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
