import React, { useState } from 'react';
import { OffcanvasProvider, Trigger, Offcanvas } from 'react-simple-offcanvas';
import css from './Cart.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, useIntl } from 'react-intl';
import NamedLink from '../NamedLink/NamedLink';
import { TiDelete } from 'react-icons/ti';
import { createSlug } from '../../util/urlHelpers';
import { UPDATE_USER_CART, removeFromCart } from '../../ducks/user.duck';
import { IconSpinner, PrimaryButton } from '..';
import { types as sdkTypes } from '../../util/sdkLoader';
import { H6 } from '../../containers/PageBuilder/Primitives/Heading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FaCartArrowDown } from 'react-icons/fa';
import CounterInput from 'react-counter-input';
import { useConfiguration } from '../../context/configurationContext';
const { Money } = sdkTypes;
const Cart = props => {
  const { width, trigger } = props;
  const [isOpen, setIsOpen] = useState(false);
  let totalAmount = 0;
  const [deleteIndex, setDeleteIndex] = useState(null);
  const state = useSelector(state => state);
  const config = useConfiguration();
  const currency = config.currency;
  const {
    currentUser,
    listingsInCart = [],
    addToCartInProgress,
    addToCartError,
    removeFromCartInProgress,
    removeFromCartError,
    userCart,
  } = state.user || {};
  if (!currentUser) {
    return null;
  }
  // console.log('userCart', userCart);
  const dispatch = useDispatch();
  const history = useHistory();

  const listing = listingsInCart[0];
  const listingId = listing?.id?.uuid;

  const intl = useIntl();

  const { attributes } = currentUser || {};
  const { profile } = attributes || {};
  const { publicData } = profile || {};
  const { cart } = publicData || {};
  if (!currentUser) return null;
  const routes = useRouteConfiguration();
  const listingQuantity = userCart?.find(item => item.id == listingId)?.quantity;
  const quantityMaybe = { quantity: listingQuantity };
  const deliveryMethodMaybe = { deliveryMethod: 'shipping' };
  const anyListingOutOfStock = listingsInCart.some(
    listing => listing?.currentStock?.attributes?.quantity < 1
  );
  const handleSubmit = () => {
    const titles = listingsInCart.map(listing => listing.attributes.title);

    const initialValues = {
      listing,
      orderData: {
        ...quantityMaybe,
        ...deliveryMethodMaybe,
        cart: userCart,
        title: titles.join(', '),
        // cart?.map(listingId => ({ id: listingId, quantity: 2 })),
      },
      confirmPaymentError: null,
    };
    const saveToSessionStorage = !currentUser;
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    dispatch(setInitialValues(initialValues, saveToSessionStorage));

    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        {
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
        },
        initialValues
      )
    );
  };

  return (
    <OffcanvasProvider
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <Trigger component="div" className={css.cartLabel} children={trigger} />
      <Offcanvas
        title={
          <div className={css.cartTitle}>
            <h3 className={css.title}>
              <FaCartArrowDown className={css.carticon} />
              <FormattedMessage id="Cart.Cart.cartTitle" />
            </h3>
          </div>
        }
        position="right"
        backdrop={false}
        allowClickAway={false}
        allowEsc={true}
        allowScroll={false}
        styles={{
          width: width ? width : '35vw',
          backgroundColor: 'white',
          zIndex: 1000,
        }}
        children={
          <div className={css.cartContent}>
            {removeFromCartError ? <p className={css.error}>{removeFromCartError}</p> : null}
            <div className={css.cartItems}>
              {listingsInCart.map((listing, index) => {
                const quantity = userCart?.find(item => item.id == listing.id.uuid)?.quantity;
                const price = formatMoney(intl, listing?.attributes?.price);
                const slug = createSlug(listing?.attributes?.title);
                const stock = listing?.currentStock?.attributes?.quantity;

                return (
                  <>
                    {stock < 1 ? <p className={css.error}>Out of stock</p> : null}
                    <div key={listing.id.uuid} className={css.cartItem}>
                      <NamedLink
                        name="ListingPage"
                        params={{ id: listing.id.uuid, slug }}
                        className={css.cartItemInfo}
                      >
                        {listing.attributes.title}({price})
                      </NamedLink>
                      <CounterInput
                        count={stock < quantity ? stock : quantity}
                        min={1}
                        max={stock}
                        onCountChange={(count, e) => {
                          dispatch({
                            type: UPDATE_USER_CART,
                            payload: { id: listing.id.uuid, quantity: count },
                          });
                        }}
                      />
                      <div>
                        {removeFromCartInProgress && deleteIndex == listing.id.uuid ? (
                          <IconSpinner className={css.spinner} />
                        ) : (
                          <TiDelete
                            className={css.deleteIcon}
                            onClick={() => {
                              setDeleteIndex(listing.id.uuid);
                              dispatch(removeFromCart(listing.id.uuid));
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className={css.breakdown}>
              {cart?.length ? (
                <div className={css.breakdownWrapper}>
                  <H6 as="h3" className={css.bookingBreakdownTitle}>
                    <FormattedMessage id="Cart.Cart.breakdownLabel" />
                  </H6>
                  <hr className={css.totalDivider} />
                  <div>
                    {listingsInCart.map((listing, index) => {
                      // console.log('listing', listing);
                      const quantity =
                        userCart?.find(item => item.id == listing.id.uuid)?.quantity || 1;
                      const stock = listing?.currentStock?.attributes?.quantity;
                      if (stock < 1) return;
                      const price = formatMoney(
                        intl,
                        new Money(
                          listing?.attributes?.price?.amount * quantity,
                          listing?.attributes?.price?.currency
                        )
                      );
                      totalAmount = totalAmount + listing?.attributes?.price?.amount * quantity;
                      return (
                        <div key={listing.id.uuid} className={css.cartItem}>
                          <div>
                            {listing.attributes.title} x {quantity}
                          </div>
                          <div>{price}</div>
                        </div>
                      );
                    })}
                  </div>
                  {totalAmount ? (
                    <>
                      <div className={css.cartItem}>
                        <div>
                          <FormattedMessage id="Cart.Cart.totalLabel" />
                        </div>
                        <div>{formatMoney(intl, new Money(totalAmount, currency))}</div>
                      </div>
                    </>
                  ) : null}
                  {/* <EstimatedCustomerBreakdownMaybe
                    breakdownData={breakdownData}
                    lineItems={lineItems}
                    currency={'USD'}
                    marketplaceName={'Marketplace'}
                    processName={PURCHASE_PROCESS_NAME}
                  /> */}
                </div>
              ) : null}
              {cart?.length && !anyListingOutOfStock ? (
                <PrimaryButton className={css.submitButton} onClick={handleSubmit} type="button">
                  <FormattedMessage id="Cart.Cart.buttonlabel" />
                </PrimaryButton>
              ) : null}
            </div>
          </div>
        }
      />
    </OffcanvasProvider>
  );
};

export default Cart;
